import {
  BellFilled,
  ExclamationCircleOutlined,
  GiftOutlined,
  PieChartOutlined,
  PrinterOutlined,
  ProductOutlined,
  ReconciliationOutlined,
  ShopOutlined,
  ToolOutlined,
  UserOutlined,
} from '@ant-design/icons';
import React from 'react';

export const leftMenu = [
  {icon: <ShopOutlined />, path: '/shop', label: 'Cửa hàng', key: '/shop'},
  {icon: <GiftOutlined />, path: '/shiper', label: 'Tài xế', key: '/shiper'},
  {icon: <UserOutlined />, path: '/user', label: 'Người dùng', key: '/user'},
  {
    icon: <ReconciliationOutlined />,
    path: '/category',
    label: 'Loại món ăn',
    key: '/category',
  },
  {
    icon: <ProductOutlined />,
    path: '/vouchers',
    label: 'Giảm giá',
    key: '/vouchers',
  },
  {
    icon: <ToolOutlined />,
    path: '/system-config',
    label: 'Bảng giá cửa hàng',
    key: '/system-config',
  },
  {
    icon: <ToolOutlined />,
    path: '/system-config-ship',
    label: 'Bảng giá tài xế',
    key: '/system-config-ship',
  },
  {
    icon: <PrinterOutlined />,
    path: '/orders',
    label: 'Đơn hàng',
    key: '/orders',
  },
  {
    icon: <PieChartOutlined />,
    path: '/statistic',
    label: 'Thống kê',
    key: '/statistic',
  },
  {
    icon: <ExclamationCircleOutlined />,
    path: '/reports',
    label: 'Báo cáo',
    key: '/reports',
  },
  {
    icon: <BellFilled />,
    path: '/notification',
    label: 'Gửi thông báo',
    key: '/notification',
  },
];
