import { create } from "zustand";


type User = {
    numberPhone: string,
    role: string
};



type AuthStore = {
    user: User | null;
    isAuthenticated: boolean,
    setLogin: () => void;
    setUser: (user: User) => void;
    logout: () => void;
};

export const useAuthStore = create<AuthStore>((set) => ({
    user: null,
    isAuthenticated: localStorage.getItem('token') ? true : false,
    setLogin: () => set({ isAuthenticated: true }),
    setUser: (user) => set({ user: user }),
    logout: () => {
        localStorage.removeItem('token');
        set({ user: null, isAuthenticated: false })
    },
}));
