import React, {useState} from 'react';
import {useNavigate} from 'react-router-dom';
import {Button, Tag, Input} from 'antd';
import type {TableColumnsType} from 'antd';
import {ReadOutlined} from '@ant-design/icons';
import {useGetOrders} from 'hooks/order/useGetOrder';
import type {SearchProps} from 'antd/es/input/Search';

import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {addKeyData, formatNumberValue, formatDate} from 'utils';
export const Orders: React.FC = () => {
  const navigate = useNavigate();
  const [filters, setFilters] = useState({});
  const {data: getData, isLoading} = useGetOrders(filters);

  const statusOrder: Record<string, string> = {
    canceled: 'red',
    initialize: 'blue',
    'ship-confirmed': 'orange',
    'ship-delivery': 'lime',
    completed: 'green',
  };
  const columns: TableColumnsType = [
    {title: 'Mã Order', dataIndex: 'code', key: 'code', align: 'center'},
    {
      title: 'Địa chỉ người nhận',
      dataIndex: 'addressReceiver',
      key: 'addressReceiver',
      align: 'center',
    },
    {
      title: 'Tên Shop',
      dataIndex: 'shop',
      key: 'shop',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: 'Tên người nhận',
      dataIndex: 'client',
      key: 'client',
      align: 'center',
      render: (data: any) => {
        return <div>{data?.name}</div>;
      },
    },
    {
      title: 'Phụ phí',
      dataIndex: 'subFee',
      key: 'subFee',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Phí Ship',
      dataIndex: 'shipFee',
      key: 'shipFee',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'status',
      key: 'status',
      align: 'center',
      render: (data: string) => {
        return (
          <Tag color={statusOrder[`${data}`]}>{data.toLocaleUpperCase()}</Tag>
        );
      },
      filters: [
        {value: 'initialize', text: 'Initialize'},
        {value: 'driver-confirmed', text: 'Driver Confirmed'},
        {value: 'client-paying', text: 'Client Paying'},
        {value: 'driver-confirming-payment', text: 'Driver Confirming Payment'},
        {value: 'payment-failure', text: 'Payment Failed'},
        {value: 'driver-to-shop', text: 'Driver To Shop'},
        {value: 'driver-delivery', text: 'Driver Delivery'},
        {value: 'completed', text: 'Completed'},
        {value: 'canceled', text: 'Canceled'},
        {value: 'auto-completed', text: 'Auto Completed'},
      ],
    },
    {
      title: 'Ngày đặt',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 200,
      render: (record: any) => {
        return (
          <div className="flex justify-center gap-4 px-0">
            <Button
              onClick={() => {
                navigate(`/orders/${record?._id}`, {
                  state: {from: `/orders`},
                });
              }}
              icon={<ReadOutlined />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chi tiết đơn hàng
            </Button>
            {/* <Button className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
              Xoá
            </Button> */}
          </div>
        );
      },
    },
  ];
  const handleTableChange = (filter: any, sorter: any) => {
    const {status} = filter;
    if (status) {
      setFilters({...filters, status: status});
    } else {
      setFilters({...filters, status: []});
    }
  };
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, code: value});
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Orders</h2>
          <div>
            <Input.Search
              className="w-96"
              placeholder="Tìm kiếm mã đơn hàng"
              onSearch={onSeachValue}
              enterButton
            />
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
            onChange={handleTableChange}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
    </div>
  );
};
