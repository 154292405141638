import React from 'react';
import {Tabs} from 'antd';
import {OrderStatistic} from 'components/statistic/order';
import {UserStatistic} from 'components/statistic/user';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  ArcElement
} from 'chart.js';

export const Statistic: React.FC = () => {
  ChartJS.register(
    ArcElement,
    CategoryScale,
    PointElement,
    LineElement,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
  );
  const tabItem = [
    {key: 'order', label: 'Đơn hàng', children: <OrderStatistic />},
    {key: 'user', label: 'Người dùng', children: <UserStatistic/>},
  ];
  return (
    <div >
      <Tabs type="card" items={tabItem} />
    </div>
  );
};
