import { AxiosError } from 'axios';
import Api from "configs";
import { useMutation } from "@tanstack/react-query";
import UpdateVoucher from "types/voucher/index";

export const useUpdateVoucher = () => {
    return useMutation({
        mutationKey: ['updateVoucher'],
        mutationFn: async (variables: { body: UpdateVoucher, idVoucher: string }) => {
            const { idVoucher, body } = variables;
            const { data } = await Api.put(`/vouchers/${idVoucher}`, body);
            return data;
        }
    })
}

export const useCreateVoucher = () => {
    return useMutation({
        mutationKey: ['createVoucher'],
        mutationFn: async (body: UpdateVoucher) => {
            const { data } = await Api.post(`/vouchers`, body);
            return data;
        }
    })
}