import React, {useState} from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Divider,
  message,
  Upload,
  Select,
} from 'antd';
import {debounce} from 'lodash';

import type {FormInstance} from 'antd';
import {PlusOutlined, LoadingOutlined} from '@ant-design/icons';
import {useUpdateShiper, useCreateShiper} from 'hooks/shiper/useUpdateShiper';
import {useUpload} from 'hooks/upload/useUpload';
import {useGetSystemConfig} from 'hooks/useSystemConfig/useGetSystemConfig';

interface Props {
  form: FormInstance<any>;
  isShowPopup: boolean;
  setIsShowPopup: (value: boolean) => void;
  refetch: () => void;
  isId: string;
  setIsId: (value: string) => void;
}
export const FormShip: React.FC<Props> = ({
  form,
  isShowPopup,
  setIsShowPopup,
  isId,
  setIsId,
  refetch,
}) => {
  const [filters, setFilters] = useState({});
  const [isChangePassword, setIsChangePassword] = useState(false);
  const {data: dataSystem, isLoading} = useGetSystemConfig({
    ...filters,
    typeSystem: 'ship',
  });
  const {mutateAsync: upLoadImage, isLoading: isLoadingUpload} = useUpload();
  const [messageApi, contextHolder] = message.useMessage();
  const {
    mutateAsync,
    isLoading: isCreate,
    isError: isErrorCreate,
  } = useCreateShiper();

  const {
    mutateAsync: onUpdateUser,
    isLoading: isEdit,
    isError,
  } = useUpdateShiper();
  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
      const data = {...value};
      if (isId) {
        await onUpdateUser({
          idUser: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      await refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      if (error?.data?.message) {
        messageApi.error(error?.data?.message);
      }
    }
  };
  const beforeUpload = async (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      console.log('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.log('Image must smaller than 2MB!');
    }
    return false;
  };
  const handleChange = async (e: any) => {
    const {file} = e;
    const formData = new FormData();
    formData.append('file', file);
    const res = await upLoadImage({body: formData});
    form.setFieldsValue({
      avatar: res,
    });
  };
  const handleSearch = debounce(e => {
    if (e === null || e === undefined) return;
    setFilters({
      ...filters,
      name: e,
    });
  }, 1000);
  const optionsSystem = (arr = []) => {
    const newArrary = arr.map((item: any) => {
      return {
        label: item.name,
        value: item?._id,
      };
    });
    return newArrary;
  };
  return (
    <Modal
      title={!isId ? 'Thêm tài xế' : 'Chỉnh sửa tài xế'}
      open={isShowPopup}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={onSubmit}
      confirmLoading={isCreate || isEdit}
      onCancel={() => {
        form.resetFields();
        setIsChangePassword(false);
        setIsShowPopup(false);
      }}>
      {contextHolder}
      <div className="container mx-auto ">
        <Divider className="my-2" />

        <Form form={form} className="py-1">
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Ảnh đại diện</label>
                <Form.Item name="avatar" className="mb-0">
                  <Upload
                    listType="picture-card"
                    showUploadList={false}
                    fileList={[]}
                    onChange={handleChange}
                    beforeUpload={beforeUpload}>
                    {form.getFieldValue('avatar') ? (
                      <img
                        src={form.getFieldValue('avatar')}
                        alt="avatar"
                        style={{width: '100%'}}
                      />
                    ) : (
                      <Button
                        style={{
                          border: 0,
                          background: 'transparent',
                          boxShadow: 'none',
                        }}>
                        {isLoadingUpload ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined />
                        )}
                        <div style={{marginTop: 8}}>Upload</div>
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Tên Shiper</label>
                <Form.Item name="name" className="mb-0">
                  <Input placeholder="Tên User" />
                </Form.Item>
              </div>
            </Col>

            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Số điện thoại</label>
                <Form.Item name="numberPhone" className="mb-0">
                  <Input
                    readOnly={isId ? true : false}
                    placeholder="Số điện thoại"
                  />
                </Form.Item>
              </div>
            </Col>
            {isId ? (
              <Col span={isChangePassword ? 24 : 12}>
                {isChangePassword ? (
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <div className="flex flex-col gap-2">
                        <label className="text-2l font-bold">
                          Mật khẩu mới
                        </label>
                        <Form.Item
                          name="password"
                          className="mb-0"
                          rules={[
                            {
                              required: true,
                              message: 'Không được để trống!',
                            },
                          ]}>
                          <Input.Password />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flex flex-col gap-2">
                        <label className="text-2l font-bold">
                          Nhập lại mật khẩu
                        </label>
                        <Form.Item
                          name="confirmPassword"
                          className="mb-0"
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Không được để trống!',
                            },
                            ({getFieldValue}) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Mật khẩu không trùng nhau!'),
                                );
                              },
                            }),
                          ]}>
                          <Input.Password />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flex items-end w-full	">
                        <Button
                          danger
                          onClick={() => {
                            form.setFieldsValue({
                              password: '',
                              confirmPassword: '',
                            });
                            setIsChangePassword(false);
                          }}>
                          Trở lại
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="flex items-end w-full h-full	">
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsChangePassword(true);
                      }}>
                      Đổi mật khẩu
                    </Button>
                  </div>
                )}
              </Col>
            ) : (
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Mật khẩu</label>
                  <Form.Item
                    name="password"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: 'Không được để trống!',
                      },
                    ]}>
                    <Input.Password />
                  </Form.Item>
                </div>
              </Col>
            )}
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Tên xe</label>
                <Form.Item name="vehicleName" className="mb-0">
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Biển số xe</label>
                <Form.Item name="vehicleBKS" className="mb-0">
                  <Input />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Bảng Giá</label>
                <Form.Item
                  name="systemConfigId"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Select
                    showSearch
                    loading={isLoading}
                    placeholder="Tìm kiếm tên bảng giá"
                    onSearch={handleSearch}
                    filterOption={false}
                    options={optionsSystem(dataSystem?.result)}
                    allowClear
                  />
                </Form.Item>
              </div>
            </Col>
            <Col span={24}>
              <Row gutter={[12, 12]}>
                <Col span={12}>
                  <div className="flex flex-col gap-2">
                    <label className="text-2l font-bold">Chủ tài khoản</label>
                    <Form.Item name="infoBankAccount.name" className="mb-0">
                      <Input disabled />
                    </Form.Item>
                  </div>
                </Col>
                <Col span={12}>
                  <div className="flex flex-col gap-2">
                    <label className="text-2l font-bold">STK</label>
                    <Form.Item name="infoBankAccount.number" className="mb-0">
                      <Input disabled />
                    </Form.Item>
                  </div>
                </Col>
                {form.getFieldValue('infoBankAccount.qr') && (
                  <Col span={12}>
                    <div className="flex flex-col gap-2">
                      <label className="text-2l font-bold">Mã QR</label>
                      <img
                        src={form.getFieldValue('infoBankAccount.qr')}
                        alt="avatar"
                        style={{width: '100%'}}
                      />
                    </div>
                  </Col>
                )}
              </Row>
            </Col>
            <Col span={24}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Địa chỉ</label>
                <Form.Item name="address" className="mb-0">
                  <Input.TextArea autoSize={{minRows: 3, maxRows: 8}} />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
      </div>
    </Modal>
  );
};
