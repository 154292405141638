
import Api from "configs";
import { useQuery } from "@tanstack/react-query";


export const useGetMenuByShopId = (id: string) => {
    return useQuery({
        queryKey: ['useGetMenuByShopId', id],
        queryFn: async () => {
            const { data } = await Api.get(`/menu/shops/${id}`);
            return data;
        },
    });
}