import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery, useMutation } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetOrders = (filters: GetFilters) => {
    const { page = 1, pageSize = 10, status = [], code, userID } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page,
    }

    if (status.length > 0) {
        options.query = {
            ...options.query,
            status: { $in: status }
        }
    }
    else {
        options.query = {
            ...options.query,
            status: null
        }
    }
    if (code) {
        options.query = {
            ...options.query,
            code: code
        }
    }

    if (userID) {
        options.query = {
            ...options.query,
            client: userID
        }
    }

    return useQuery<GetResponse, AxiosError>({
        queryKey: ['orders', options],
        queryFn: async () => {
            try {
                const response = await Api.get<GetResponse>(`/admin/orders`, {
                    params: options
                });
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        staleTime: 1000 * 60 * 5
    });
}


export const useGetOrderById = (id: string) => {
    return useQuery({
        queryKey: ['order', id],
        queryFn: async () => {
            try {
                const response = await Api.get(`/admin/orders/${id}`);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        staleTime: 1000 * 60 * 5
    });
}
export const useCancelOrderById = () => {
    return useMutation({
        mutationKey: ['orderCanCel'],
        mutationFn: async (variables: { id: string, reason: string }) => {
            try {
                const { id, reason } = variables;
                const body = {
                    cancelReason: reason,
                };
                const response = await Api.put(`/admin/orders/${id}/cancel`, body);
                return response.data;
            } catch (error) {
                throw error;
            }
        },
    });
}


export const useGetOrdersByUser = (filters: GetFilters) => {
    const { page = 1, pageSize = 10, status = [], code, userID } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page,
    }

    if (status.length > 0) {
        options.query = {
            ...options.query,
            status: { $in: status }
        }
    }
    else {
        options.query = {
            ...options.query,
            status: null
        }
    }
    if (code) {
        options.query = {
            ...options.query,
            code: code
        }
    }

    if (userID) {
        options.query = {
            ...options.query,
            client: userID
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['ordersbyUser', userID,options],
        queryFn: async ({ queryKey }) => {
            try {
                // if (userID === undefined) {
                //     return { data: [], countCompleted : 0, totalCount : 0 }
                // }
                const response = await Api.get<GetResponse>(`/admin/orders`, {
                    params: options
                });
                return response.data;
            } catch (error) {
                throw error;
            }
        },
        staleTime: 1000 * 60 * 5
    });
}
