import * as React from 'react';
import { useState } from 'react';
import ReactMapGL, { Marker } from '@goongmaps/goong-map-react';
import { Typography, Flex, Image, Spin, message, Button } from 'antd';
import { useMutation } from '@tanstack/react-query';
import axios from 'axios';
import { GOONG_BASE_URL, GOONG_KEY } from 'configs';



async function getPlaceDetail(lat: number, long: number) {
    try {
        const {data} = await axios.get(`${GOONG_BASE_URL}/Geocode`, {
            params: {
          latlng: `${lat},${long}`,
          api_key: GOONG_KEY,
        },
      });

      const {data:placeDetail} = await axios.get(`${GOONG_BASE_URL}/Place/Detail`, {
        params: {
          api_key: GOONG_KEY,
          place_id: data.results[0].place_id,
        },
      });
      return  placeDetail?.result?.formatted_address ?? 'Không tìm thấy địa chỉ';
    } catch (error) {
        return message.error('Không tìm thấy địa chỉ, hoặc lỗi api maps');
    }
}

const useGetPlaceDetail = () => {
     const {data,mutate, isLoading} = useMutation({
        mutationFn: ({ lat, long }: { lat: number, long: number }) => getPlaceDetail(lat, long)
    })
    return {data,mutate,isLoading}
}

const { Text } = Typography;


type AddressPickerProps = { 
  onConfirm: (address: string, lat: number, long: number) => void;
  defaultLat?: number;
  defaultLong?: number;
}
export default function AddressPicker({onConfirm, defaultLat, defaultLong}: AddressPickerProps) {
  const [isDragging, setIsDragging] = useState(false);
  const [viewport, setViewport] = useState({
      width: '90%',
      height: '600px',
      latitude: defaultLat || 21.00966313600003,
      longitude: defaultLong || 107.27755274700007,
      })

  const {data,mutate,isLoading} = useGetPlaceDetail()

  const handleGetPlaceDetail = () => {
      console.log(viewport.latitude, viewport.longitude);
      mutate({lat:viewport.latitude,long:viewport.longitude})
  }

  const handleConfirmAddress = () => {
      onConfirm(data, viewport.latitude, viewport.longitude)
  }
  return (
    <>
      <ReactMapGL
        {...viewport}
        goongApiAccessToken="zUHfagDW4w45gWDJs3qEqd9TXcYTASX3OJK2Zcmd"
        mapStyle="https://tiles.goong.io/assets/goong_map_web.json"
        onInteractionStateChange={(e: any) =>{
            if(!e.isDragging) {handleGetPlaceDetail(); setIsDragging(false)}
            else setIsDragging(true)
        }}
        scrollZoom={false}
        zoom={15}
        onViewportChange={(
          nextViewport: React.SetStateAction<{
            width: string
            height: string
            latitude: number
            longitude: number
            zoom: number
          }>,
        ) => setViewport(nextViewport)}
      >
      
          <Marker latitude={viewport.latitude} longitude={viewport.longitude}>
            <Flex vertical>
            <Image src={'/marker.png'} alt="marker" width={30} height={30} preview={false}/>
            {isLoading || isDragging ? <Spin /> : <Text className='font-semibold text-blue-500'>{data}</Text>}
          </Flex>
          </Marker>
      </ReactMapGL>
      <Button type='primary' className='mt-4' onClick={handleConfirmAddress}>Xác nhận địa chỉ</Button>
    </>
  )
}
