import React from 'react';
import {Form, Space, Input, Button} from 'antd';
import {MinusCircleOutlined, PlusOutlined} from '@ant-design/icons';
import {formatNumberToString} from 'utils';
interface Props {
  name: string;
  label: string;
}

export const AddItemForm: React.FC<Props> = ({name, label}) => {
  const normalizePrice = (value: any) => {
    return formatNumberToString(value);
  };

  return (
    <div>
      <Form.List name={name}>
        {(fields, {add, remove}) => (
          <>
            {fields.map(({key, name, ...restField}) => (
              <Space
                key={key}
                style={{display: 'flex', marginBottom: 8}}
                align="baseline">
                <Form.Item
                  {...restField}
                  name={[name, 'first']}
                  rules={[{required: true, message: 'Không được để trống'}]}>
                  <Input placeholder={`${label}`} />
                </Form.Item>
                <Form.Item
                  {...restField}
                  name={[name, 'last']}
                  rules={[{required: true, message: 'Không được để trống'}]}
                  normalize={normalizePrice}>
                  <Input placeholder="Giá" />
                </Form.Item>
                <MinusCircleOutlined onClick={() => remove(name)} />
              </Space>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => add()}
                block
                icon={<PlusOutlined />}>
                Thêm {label}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
