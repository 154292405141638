import React, { useEffect,useState } from 'react';
import {Tag} from 'antd';
import type {SelectProps} from 'antd';
type TagRender = SelectProps['tagRender'];

export const TagRenderFC: TagRender = props => {
  const {label, closable, onClose} = props;
  const [color, setColor] = useState<string>();
  const onPreventMouseDown = (event: React.MouseEvent<HTMLSpanElement>) => {
    event.preventDefault();
    event.stopPropagation();
  };

  useEffect(()=>{
    const value ='#' + Math.floor(Math.random() * 16777215).toString(16);
    setColor(value);
  },[label])
  return (
    <Tag
      color={color}
      onMouseDown={onPreventMouseDown}
      closable={closable}
      onClose={onClose}
      style={{marginRight: 3}}>
      {label}
    </Tag>
  );
};
