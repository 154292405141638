import {PoweroffOutlined, UserOutlined} from '@ant-design/icons';
import {Avatar, Dropdown, type MenuProps} from 'antd';
import React, {startTransition} from 'react';
import {useAuthStore} from 'stores/auth';

export const UserInfo: React.FC = () => {
  const {logout} = useAuthStore(state => state);

  const items: MenuProps['items'] = [
    {
      key: '1',
      icon: <UserOutlined />,
      label: 'Admin',
    },
    {
      type: 'divider',
    },
    {
      key: '3',
      icon: <PoweroffOutlined />,
      label: 'Đăng xuất',
      onClick: () => {
        startTransition(() => {
          logout();
          //navigate('/login');
        });
      },
    },
  ];
  return (
    <Dropdown menu={{items}} placement="bottom" trigger={['click']}>
      <div className="flex items-center cursor-pointer">
        <Avatar
          style={{backgroundColor: '#fde3cf', color: '#f56a00'}}
          className="mr-1 w-8 h-8">
          Admin
        </Avatar>
        <span>Người quản lí</span>
      </div>
    </Dropdown>
  );
};
