import React from 'react';
import {
  Button,
  Select,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Divider,
  message,
} from 'antd';
import type {TableColumnsType, FormInstance} from 'antd';
import {
  useUpdateSystemConfig,
  useCreateSystemConfig,
} from 'hooks/useSystemConfig/useUpdateSystemConfig';
import {formatStringToNumber,formatNumberToString} from 'utils/index';
const {Option} = Select;

interface Props {
  isId: string;
  setIsId: (value: string) => void;
  setIsShowPopup: (value: boolean) => void;
  isShowPopup: boolean;
  form: FormInstance<any>;
  refetch: () => void;
}


export const FormSystemShip: React.FC<Props> = ({
  isId,
  setIsId,
  isShowPopup,
  setIsShowPopup,
  form,
  refetch,
}) => {
  const {mutateAsync, isLoading: isCreate} = useCreateSystemConfig();
  const {mutateAsync: onUpdateUser, isLoading: isEdit} =
    useUpdateSystemConfig();
  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
      const isPercentShipFee = form.getFieldValue('isPercentShipFee');
      const data = {
        ...value,
        shipFeePerOrder: formatStringToNumber(value.shipFeePerOrder),
        isPercentShipFee,
        type: 'ship'
      };
     
      if (isId) {
        await onUpdateUser({
          id: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      if(error?.data?.message){
        message.error(error?.data?.message);
      }
    }
  };

  const selectAfter = (filed: string,filedValue: string) => {
    const value = form.getFieldValue(filed);
    return (
      <Select
        style={{width: '80px'}}
        defaultValue={value ? 'percent' : 'vnd'}
        onChange={e => {
            form.setFieldsValue({[filed]: e === 'percent' ? true : false});
            form.setFieldsValue({[filedValue]: ''});
        }}>
        <Option value="percent">%</Option>
        <Option value="vnd">VNĐ</Option>
      </Select>
    );
  };
  const normalizePrice = (value: any) => {
    return formatNumberToString(value);
  };

  return (
    <Modal
      title={!isId ? 'Thêm Config Shop' : 'Chỉnh sửa Config Shop'}
      open={isShowPopup}
      width={800}
      okText="Lưu"
      cancelText="Huỷ"
      onOk={onSubmit}
      confirmLoading={isCreate || isEdit}
      onCancel={() => {
        form.resetFields();
        setIsShowPopup(false);
      }}>
      <div className="container mx-auto ">
        <Divider className="my-2" />
        <Form form={form} className="py-1" >
          <Row gutter={[12, 12]}>
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Tên System Config</label>
                <Form.Item
                  name="name"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Loại System không được bỏ trống !',
                    },
                  ]}>
                  <Input placeholder="Tên System Config" />
                </Form.Item>
              </div>
            </Col>
      
            <Col span={12}>
              <div className="flex flex-col gap-2">
                <label className="text-2l font-bold">Phí giao hàng</label>
                <Form.Item
                  name="shipFeePerOrder"
                  className="mb-0"
                  normalize={normalizePrice}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống!',
                    },
                    {
                        validator:async  (rule, value) => {
                          const isCheck = form.getFieldValue('isPercentShipFee');
                          if(isCheck){
                             const numberPercent = formatStringToNumber(value);
                             if(numberPercent > 100){
                              throw new Error('Phí giao hàng hàng không thể lớn hơn 100%');
                             }
                          }
                        }
                    }
                  ]}>
                  <Input placeholder="Phí shiper" addonAfter={selectAfter('isPercentShipFee','shipFeePerOrder')} />
                </Form.Item>
              </div>
            </Col>
          </Row>
        </Form>
        <Divider className="my-2" />
      </div>
    </Modal>
  );
};
