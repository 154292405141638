import Api from "configs";
import { useMutation } from "@tanstack/react-query";
import UpdateShiper from "types/shiper/index";

export const useUpdateShiper = () => {
    return useMutation({
        mutationKey: ['updateShiper'],
        mutationFn: async (variables: { body: UpdateShiper, idUser: string }) => {
            const { idUser, body } = variables;
            const { data } = await Api.put(`/admin/shipers/${idUser}`, body);
            return data;
        }
    })
}


export const useToggleActiveShiper = () => {
    return useMutation({
        mutationKey: ['useToggleActiveShiper'],
        mutationFn: async (idUser: string) => {
            const { data } = await Api.put(`/admin/shipers/active/${idUser}`);
            return data;
        }
    })
}


export const useCreateShiper = () => {
    return useMutation({
        mutationKey: ['useCreateShiper'],
        mutationFn: async (body: UpdateShiper) => {
            const { data } = await Api.post(`/admin/shipers`, body);
            return data;
        }
    })
}


export const useDeleteShipers =  ()=>{
    return useMutation({
       mutationKey: ['useDeleteShipers'],
       mutationFn: async (ids: string) => {
           const { data } = await Api.delete(`/admin/shipers/${ids}`);
           return data;
       }
    })
}