import {EditOutlined, RollbackOutlined} from '@ant-design/icons';
import {Button, Col, Form, Row, Skeleton, message} from 'antd';
import {BlockList} from 'components/shop/BlockList';
import {FormShop} from 'components/shop/FormShop';
import dayjs from 'dayjs';
import {useGetShopById} from 'hooks/shop/useGetShop';
import {useUpdateShop} from 'hooks/shop/useUpdateShop';
import React, {useEffect, useState, useTransition} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
const format = 'HH:mm';

export const EditShop: React.FC = () => {
  const params = useParams();
  const {
    data,
    isLoading: isLoadingShop,
    isError,
    isSuccess,
    refetch,
  } = useGetShopById(params?.id || '');
  const navigate = useNavigate();
  const [_, startTransition] = useTransition();
  const [form] = Form.useForm();
  const {mutateAsync: onUpdateShop, isLoading} = useUpdateShop();

  // Add state to track form dirty status
  const [isFormDirty, setIsFormDirty] = useState(false);

  useEffect(() => {
    form.resetFields();
    setIsFormDirty(false); // Reset dirty status when data loads
    if (isSuccess) {
      form.setFieldsValue({
        ...data,
        numberPhone: data?.user?.numberPhone,
        openingHour: dayjs(data?.openingHour, format),
        closingHour: dayjs(data?.closingHour, format),
      });
    }
    if (isError) {
      message.error(`Không tìm thấy shop có id: ${params?.id}`);
      navigate('/shop', {
        state: {from: `/shop/edit/${params?.id}`},
      });
    }
  }, [isSuccess, data]);

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      await onUpdateShop({
        idShop: data?.user?._id || '',
        body: {
          ...values,
          openingHour: values?.openingHour?.format(format),
          closingHour: values?.closingHour?.format(format),
        },
        idQuery: data?._id,
      });
      message.success('Cập nhật thông tin shop thành công');
      setIsFormDirty(false); // Reset dirty status after successful save
      refetch();
    } catch (error) {
      if (error?.data?.message) {
        message.error(error?.data?.message);
      }
    }
  };

  return (
    <div className="mx-auto container">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="font-bold text-2xl">Chỉnh sửa thông tin Shop</h2>
          <div className="flex items-center gap-3">
            <Button
              icon={<RollbackOutlined />}
              onClick={() => {
                navigate(-1);
              }}>
              Trở về
            </Button>
            <Button
              disabled={!isFormDirty} // Disable button when form is not dirty
              loading={isLoading}
              className="bg-[#eca52b] hover:!bg-[#edb555] !border-white !font-medium !text-white"
              icon={<EditOutlined />}
              onClick={() => {
                startTransition(() => {
                  handleSave();
                });
              }}>
              Chỉnh sửa
            </Button>
          </div>
        </div>
        <Skeleton active loading={isLoadingShop}>
          <FormShop
            form={form}
            isEdit={true}
            onValuesChange={() => {
              if (!isFormDirty) {
                setIsFormDirty(true);
              }
            }}
          />
          <Row>
            <Col span={24}>
              <h3 className="font-bold text-xl">Danh sách tài xế bị chặn</h3>
            </Col>
            <Col span={24}>
              <BlockList shopId={params?.id || ''} data={data?.blockedlist} />
            </Col>
          </Row>
        </Skeleton>
      </div>
    </div>
  );
};
