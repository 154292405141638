import React, {useMemo, useEffect, useState} from 'react';
import type {DatePickerProps} from 'antd';
import {DatePicker, Select, Skeleton} from 'antd';
import {Bar, Line} from 'react-chartjs-2';
import {useGetStatisticsOrder} from 'hooks/statistics';
import dayjs from 'dayjs';
import {formatNumberToString} from 'utils';

export const OrderStatistic: React.FC = () => {
  const [year, setYear] = useState<number>();
  const [dataChart, setDataChart] = useState<any>();
  const [dataFund, setDataFund] = useState<any>();
  const [typeSelect, setTypeSelect] = useState<string>('year');
  const {data: getData, isLoading, isSuccess} = useGetStatisticsOrder({year, typeChart: typeSelect});
  const onChange: DatePickerProps['onChange'] = (date, dateString) => {
    setYear(dayjs(date).valueOf());
  };
  const options = useMemo(
    () => ({
      responsive: true,
      plugins: {
        legend: {
          position: 'bottom' as const,
        },
      },
      scales: {
        y: {
          min: 0,
          ticks: {
            precision: 0
          }
        },
        
      }
    }),
    [],
  );

  useEffect(() => {
    setYear(dayjs().valueOf());
  }, []);
  useEffect(() => {
    if (isSuccess) {
      const labels = getData.map((item: any) => item.label);
      const totalCancel = getData.map((item: any) => item.totalCancel);
      const totalCompleted = getData.map((item: any) => item.totalCompleted);
      const newData = {
        labels: labels,
        datasets: [
          {
            label: 'Hoàn thành',
            data: totalCompleted,
            backgroundColor: 'green',
           
          },
          {
            label: 'Bị huỷ',
            data: totalCancel,
            backgroundColor: 'red',
            
          },
        ],
      };
      setDataChart(newData);
      setDataFund({
        labels: labels,
        datasets: [
          {
            label: 'Doanh thu',
            data: getData.map((item: any) => item.totalFund),
            borderColor: 'rgb(53, 162, 235)',
            backgroundColor: 'rgba(53, 162, 235, 0.5)',
          },
        ],
      });
    }
  }, [isSuccess, getData]);
  const sumTotalCost = (arr = []) => {
    const result = arr?.reduce((sum: number, item: any) => {
      return sum + item.totalFund;
    }, 0);
    return `${formatNumberToString(result)} VNĐ`;
  };
  return (
    <div className="flex flex-col">
      {isLoading ? (
        <Skeleton active />
      ) : (
        <div className="flex flex-col">
          <div className="flex flex-col">
            <div className="flex justify-between">
              {' '}
              <div>
                <h1>Tổng số đơn hàng</h1>
              </div>
              <div className="flex items-center">
                {' '}
                <Select value={typeSelect} onChange={(e)=> setTypeSelect(e)} style={{width: '100px'}}>
                  <Select.Option value="month">Tháng</Select.Option>
                  <Select.Option value="year">Năm</Select.Option>
                </Select>
                <DatePicker
                  value={dayjs(year)}
                  placeholder="Chọn năm"
                  onChange={onChange}
                  picker={typeSelect === 'month' ? 'month' : 'year'}
                />
              </div>
            </div>
            {!dataChart ? null : (
              <div
                className="flex justify-center "
                style={{
                  width: '100%',
                  height: 400,
                }}>
                <Bar options={options} data={dataChart} />
              </div>
            )}
          </div>
          <div>
            <div>
              <h1>
                Doanh thu năm {dayjs(year).year()}: {sumTotalCost(getData || [])}
              </h1>
            </div>
            {!dataFund ? null : (
              <div
                className="flex justify-center "
                style={{
                  width: '100%',
                  height: 500,
                }}>
                <Line options={options} data={dataFund} />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
