import type {TableColumnsType, TableProps} from 'antd';
import {Table} from 'antd';
import React from 'react';

interface TableType {
  columns: TableColumnsType;
  data: any[];
  isLoading?: boolean;
  onChange?: (pagination?: any, filters?: any, sorter?: any) => void;
}

export const TableCustom: React.FC<TableType> = props => {
  const {isLoading, columns, data, onChange} = props;

  const handleTableChange: TableProps['onChange'] = (
    pagination,
    filters,
    sorter: any,
  ) => {
    if (onChange) {
      const {order, field} = sorter;
      let sort = null;
      if (order) {
        sort = {
          [field]: order === 'ascend' ? 1 : -1,
        };
      }
      onChange(filters, sort);
    }
  };

  return (
    <Table
      //  className='h-80 max-h-80 overflow-y-scroll'
      bordered={true}
      virtual
      loading={isLoading}
      columns={columns}
      dataSource={data}
      pagination={false}
      onChange={handleTableChange}
      scroll={{x: 1200, y: 590}}
    />
  );
};
