import axios from 'axios';

export const URL = process.env.REACT_APP_API_ENDPOINT;
export const GOONG_KEY = process.env.REACT_APP_GOONG_KEY;
export const GOONG_BASE_URL = process.env.REACT_APP_GOONG_BASE_URL;

const Api = axios.create({
    baseURL: URL,
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'Cache-Control': 'no-cache',
        'Access-Control-Allow-Origin': '*',
    },
});

Api.interceptors.request.use(async config => {
    const accessToken = await localStorage.getItem('token');
    if (accessToken) {
        config.headers.Authorization = `Bearer ${accessToken}`;
    }
    return config;
});

Api.interceptors.response.use(
    response => {
        return response;
    },
    async error => {
        if ([401].includes(error?.response?.status)) {
            //logout
        }
        throw error.response;
    },
);
export default Api;