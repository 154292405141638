import dayjs from 'dayjs';
export const addKeyData = (arr = []) => {
  const newArray = arr.map((item: any, index) => {
    return { ...item, key: item?._id };
  });
  return newArray;
};

export const formatStringToNumber = (str: string) => {
  return Number(str.replace(/,/g, ''));
}

export const formatNumberToString = (value: number) => {
  if (value === null || value === undefined) return 0;
  // const formattedValue = value.toString()
  //   .replace(/[^\d.]/g, '')
  //   .replace(/^\./, '')
  //   .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  const formattedValue = value.toString()
    .replace(/[^\d.]/g, '')
    .replace(/^\./, '')
    .replace(/\.(?=.*\.)/g, '') 
    .replace(/\B(?=(\d{3})+(?!\d))/g, ','); 
  return formattedValue;
}


export const formatNumberValue = (value: number, SGD = 'VNĐ') => {
  if (value === null || value === undefined) return 0;
  let hasMinus = false;
  const formattedValue = value.toString()
    .split('')
    .filter(char => {
      if (char === '-' && !hasMinus) {
        hasMinus = true;
        return true;
      } else if (char === '-') {
        return false;
      }
      return char.match(/[0-9]/);
    })
    .join('')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return `${formattedValue} ${SGD}`;
}



export const formatNumberNegativeToString = (value: number) => {
  if (value === null || value === undefined) return 0;
  let hasMinus = false;
  const formattedValue = value.toString()
    .split('')
    .filter(char => {
      if (char === '-' && !hasMinus) {
        hasMinus = true;
        return true;
      } else if (char === '-') {
        return false;
      }
      return char.match(/[0-9]/);
    })
    .join('')
    .replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return formattedValue;
}

export const formatDate = (value: Date) => {
  return dayjs(value).format('DD/MM/YYYY HH:mm');
}