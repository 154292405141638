import React, {useState, useTransition} from 'react';

import {Table, Button, message} from 'antd';
import type {TableColumnsType} from 'antd';
import {useUnblockShip} from 'hooks/shop/useGetShop';
interface Ipros {
  data?: [];
  shopId: string;
}

export const BlockList: React.FC<Ipros> = ({data, shopId}) => {
  const {mutateAsync} = useUnblockShip();
  const handleSubmit = async (shipId: string) => {
    try {
      await mutateAsync({shopId, shipId});
      message.success('Thao tác thành công');
    } catch (error) {}
  };
  const columns: TableColumnsType = [
    {
      title: 'Tên tài xế',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      key: 'numberPhone',
      align: 'center',
      render: data => {
        return <p>{data.numberPhone}</p>;
      },
    },
    {
      title: 'Biển số xe',
      dataIndex: 'vehicleBKS',
      key: 'vehicleBKS',
      align: 'center',
    },
    {
      title: 'Tên xe',
      dataIndex: 'vehicleName',
      key: 'vehicleName',
      align: 'center',
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      render: (record: any) => {
        return (
          <Button
            className="!border-white	 bg-[#2bec82] hover:!bg-[#2bec82] !text-white !font-medium"
            onClick={() => handleSubmit(record?._id)}>
            Unblock
          </Button>
        );
      },
    },
  ];
  return <Table columns={columns} dataSource={data} />;
};
