import {
  DeleteOutlined,
  EditFilled,
  LockOutlined,
  PlusOutlined,
  UnlockOutlined,
} from '@ant-design/icons';
import type {TableColumnsType} from 'antd';
import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Popconfirm,
  Row,
  Tag,
} from 'antd';
import React, {useState, useTransition} from 'react';
import {useNavigate} from 'react-router-dom';

import type {SearchProps} from 'antd/es/input/Search';
import {useGetUsers} from 'hooks/users/useGetUsers';
import {
  useCreateUser,
  useDeleteUser,
  useToggleUser,
  useUpdateUser,
  useVerifyUser,
} from 'hooks/users/useUpdateUsers';
import {addKeyData, formatDate} from 'utils/index';

import {PaginationCustom} from 'components/element/PaginationCustom';
import {TableCustom} from 'components/element/TableCustom';
export const User: React.FC = () => {
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isId, setIsId] = useState('');
  const {data: getData, isLoading, refetch} = useGetUsers(filters);
  const [messageApi, contextHolder] = message.useMessage();
  const [isChangePassword, setIsChangePassword] = useState(false);
  const {mutateAsync, isLoading: isCreate} = useCreateUser();

  const {mutateAsync: onUpdateUser, isLoading: isEdit} = useUpdateUser();
  const {mutateAsync: onToggleUser} = useToggleUser();
  const {mutateAsync: onVerifyUser} = useVerifyUser();
  const {mutateAsync: onDeleteUser} = useDeleteUser();

  const handleconfirm = async (id: string) => {
    await onToggleUser(id);
    await refetch();
  };
  const columns: TableColumnsType = [
    {title: 'Tên User', dataIndex: 'name', key: 'name', align: 'center'},
    {
      title: 'Số điện thoại',
      dataIndex: 'user',
      key: 'numberPhone',
      align: 'center',
      render: data => {
        return <p>{data.numberPhone}</p>;
      },
    },
    {title: 'Địa chỉ', dataIndex: 'address', key: 'address', align: 'center'},
    {
      title: 'Uy tín',
      dataIndex: 'reputation',
      key: 'reputation',
      align: 'center',
      render: data => {
        return (
          <div>
            <Tag
              color={
                data?.level >= 2 ? 'green' : data?.level >= 1 ? 'yellow' : 'red'
              }>
              Mức {data.level}
            </Tag>
          </div>
        );
      },
    },
    {
      title: 'Trạng thái',
      dataIndex: 'user',
      key: 'isBlocked',
      align: 'center',
      render: (user, record: any) => {
        const data = user?.isBlocked;
        return (
          <Popconfirm
            title={`${data ? 'Unblock' : 'Block'}`}
            description={`Bạn có chắc muốn ${data ? 'Unblock' : 'Block'}`}
            okText="Yes"
            cancelText="No"
            onConfirm={() => handleconfirm(user?._id)}>
            {!data ? (
              <Button icon={<UnlockOutlined />} type="primary">
                Unblock
              </Button>
            ) : (
              <Button
                icon={<LockOutlined />}
                className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Block
              </Button>
            )}
          </Popconfirm>
        );
      },
    },
    {
      title: 'Xác minh',
      dataIndex: 'user',
      key: 'verified',
      align: 'center',
      width: 130,
      render: (data: any) => {
        return (
          <Popconfirm
            title={`Kích hoạt người dùng`}
            description={`Bạn có chắc muốn kích hoạt người dùng`}
            okText="Yes"
            cancelText="No"
            disabled={data.verified}
            onConfirm={async () => {
              await onVerifyUser(data?._id);
              await refetch();
            }}>
            <div>
              {data.verified ? (
                <Tag color="success">Đã xác minh</Tag>
              ) : (
                <Tag className="verify" color="error">
                  Chưa xác minh
                </Tag>
              )}
            </div>
          </Popconfirm>
        );
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Action',
      key: 'operation',
      align: 'center',
      width: 350,
      render: (text, record: any) => {
        return (
          <div className="flex gap-4 px-0">
            <Button
              onClick={() => {
                const data = {
                  ...record,
                  numberPhone: record.user.numberPhone,
                };
                setIsId(data?._id);
                form.setFieldsValue(data);
                setIsShowPopup(true);
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            <Button
              onClick={() => {
                startTransition(() => {
                  navigate(`/user/order/${record?._id}`, {
                    state: {from: `/shop`},
                  });
                });
              }}
              className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
              Lịch sử
            </Button>
            <Popconfirm
              title={`Xoá tài khoản`}
              description={`Bạn có chắc muốn xoá người dùng này`}
              okText="Yes"
              cancelText="No"
              onConfirm={async () => {
                await onDeleteUser(record?._id);
                await refetch();
              }}>
              <Button onClick={() => {}} icon={<DeleteOutlined />}>
                Xoá
              </Button>
            </Popconfirm>
          </div>
        );
      },
    },
  ];

  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
      const data = {...value};
      if (isId) {
        await onUpdateUser({
          idUser: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      await refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      messageApi.error(error.data.message);
      return;
    }
  };
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, search: value});
  };
  return (
    <div className="container mx-auto">
      {contextHolder}
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Người dùng</h2>
          <p>{isPending ? 'loadng' : ''}</p>
          <div className="flex gap-2">
            <div>
              <Input.Search
                className="w-96"
                placeholder="Search theo tên hoặc số điện thoại"
                onSearch={onSeachValue}
                enterButton
              />
            </div>
            <Button
              className="font-bold"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                startTransition(() => {
                  setIsId('');
                  setIsShowPopup(true);
                });
              }}>
              Thêm người dùng
            </Button>
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
      <Modal
        title={isId ? 'Thêm User' : 'Chỉnh sửa user'}
        open={isShowPopup}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={onSubmit}
        confirmLoading={isCreate || isEdit}
        onCancel={() => {
          form.resetFields();
          setIsShowPopup(false);
        }}>
        <div className="container mx-auto ">
          <Divider className="my-2" />
          <Form form={form} className="py-1">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Tên </label>
                  <Form.Item name="name" className="mb-0">
                    <Input placeholder="Tên User" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Số điện thoại</label>
                  <Form.Item name="numberPhone" className="mb-0">
                    <Input
                      readOnly={isId ? true : false}
                      placeholder="Số điện thoại"
                    />
                  </Form.Item>
                </div>
              </Col>
              {isId ? (
                <Col span={isChangePassword ? 24 : 12}>
                  {isChangePassword ? (
                    <Row gutter={[12, 12]}>
                      <Col span={12}>
                        <div className="flex flex-col gap-2">
                          <label className="text-2l font-bold">
                            Mật khẩu mới
                          </label>
                          <Form.Item
                            name="password"
                            className="mb-0"
                            rules={[
                              {
                                required: true,
                                message: 'Không được để trống!',
                              },
                            ]}>
                            <Input.Password />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex flex-col gap-2">
                          <label className="text-2l font-bold">
                            Nhập lại mật khẩu
                          </label>
                          <Form.Item
                            name="confirmPassword"
                            className="mb-0"
                            dependencies={['password']}
                            hasFeedback
                            rules={[
                              {
                                required: true,
                                message: 'Không được để trống!',
                              },
                              ({getFieldValue}) => ({
                                validator(_, value) {
                                  if (
                                    !value ||
                                    getFieldValue('password') === value
                                  ) {
                                    return Promise.resolve();
                                  }
                                  return Promise.reject(
                                    new Error('Mật khẩu không trùng nhau!'),
                                  );
                                },
                              }),
                            ]}>
                            <Input.Password />
                          </Form.Item>
                        </div>
                      </Col>
                      <Col span={12}>
                        <div className="flex items-end w-full	">
                          <Button
                            danger
                            onClick={() => {
                              form.setFieldsValue({
                                password: '',
                                confirmPassword: '',
                              });
                              setIsChangePassword(false);
                            }}>
                            Trở lại
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  ) : (
                    <div className="flex items-end w-full h-full	">
                      <Button
                        type="primary"
                        onClick={() => {
                          setIsChangePassword(true);
                        }}>
                        Đổi mật khẩu
                      </Button>
                    </div>
                  )}
                </Col>
              ) : (
                <Col span={12}>
                  <div className="flex flex-col gap-2">
                    <label className="text-2l font-bold">Mật khẩu</label>
                    <Form.Item
                      name="password"
                      className="mb-0"
                      rules={[
                        {
                          required: true,
                          message: 'Không được để trống!',
                        },
                      ]}>
                      <Input.Password />
                    </Form.Item>
                  </div>
                </Col>
              )}
              <Col span={24}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Địa chỉ</label>
                  <Form.Item name="address" className="mb-0">
                    <Input.TextArea autoSize={{minRows: 3, maxRows: 8}} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
