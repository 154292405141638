import {useQuery} from '@tanstack/react-query';
import {
  Button,
  Card,
  Checkbox,
  Flex,
  Form,
  Input,
  message,
  Select,
  Typography,
} from 'antd';
import Api from 'configs';
import {debounce} from 'lodash';
import React, {useEffect, useState} from 'react';

const {Option} = Select;
const {TextArea} = Input;

export default function Notification() {
  const [form] = Form.useForm();
  const [search, setSearch] = useState('');
  const [q, setQ] = useState('');
  const [specificUsers, setSpecificUsers] = useState<string[]>([]);
  const {data: users, isLoading: isLoadingUsers} = useQuery({
    queryKey: ['users', q],
    queryFn: () => Api.get(`/user/search?q=${q}`),
    select: data => data.data,
  });

  const [loading, setLoading] = useState(false);
  const typeRecipient = Form.useWatch(['type'], form);

  const handleSubmit = async (values: any) => {
    console.log(values);
    setLoading(true);
    await Api.post('/notification', {
      ...values,
      specificUser: specificUsers,
    });
    message.success('Gửi thông báo thành công');
    setLoading(false);
  };

  const debouncedSetQ = debounce((value: string) => {
    setQ(value);
  }, 300);

  useEffect(() => {
    if (search) {
      debouncedSetQ(search);
    }

    return () => {
      debouncedSetQ.cancel();
    };
  }, [search]);

  const handleSpecificUserChange = (checkedValues: string[]) => {
    setSpecificUsers(checkedValues);
  };

  return (
    <Card title="Gửi Thông Báo">
      <Form
        form={form}
        layout="vertical"
        onFinish={handleSubmit}
        autoComplete="off">
        <Form.Item
          name="type"
          label="Chọn Người Nhận"
          rules={[{required: true, message: 'Vui lòng chọn loại người nhận'}]}>
          <Select placeholder="Chọn loại người nhận">
            <Option value="driver">Tài Xế</Option>
            <Option value="shop">Cửa Hàng</Option>
            <Option value="user">Người Dùng</Option>
            <Option value="all">Tất Cả</Option>
            <Option value="specific">Tài khoản cụ thể</Option>
          </Select>
        </Form.Item>

        {typeRecipient === 'specific' && (
          <Flex className="pb-10">
            <Form.Item name="specificUser" label="Chọn Người Nhận" hidden />
            <Flex className="w-full" gap={32}>
              <Flex vertical gap={10} flex={1}>
                <Typography.Text>Tìm kiếm người nhận</Typography.Text>
                <Input
                  placeholder="Số điện thoại"
                  onChange={e => setSearch(e.target.value)}
                />
              </Flex>
              <Flex
                vertical
                gap={10}
                flex={2}
                className="bg-gray-100 p-4 border rounded-md h-[300px] overflow-y-auto">
                <Typography.Text>Tick chọn người nhận</Typography.Text>
                <Checkbox.Group
                  className="flex flex-col gap-3"
                  onChange={handleSpecificUserChange}>
                  {users?.map((user: any) => (
                    <Checkbox key={user._id} value={user._id}>
                      {user.numberPhone} - {user.role}
                    </Checkbox>
                  ))}
                </Checkbox.Group>
              </Flex>
            </Flex>
          </Flex>
        )}

        <Form.Item
          name="title"
          label="Tiêu Đề Thông Báo"
          rules={[
            {required: true, message: 'Vui lòng nhập tiêu đề thông báo'},
          ]}>
          <Input placeholder="Nhập tiêu đề thông báo" />
        </Form.Item>

        <Form.Item
          name="body"
          label="Nội Dung Thông Báo"
          rules={[
            {required: true, message: 'Vui lòng nhập nội dung thông báo'},
          ]}>
          <TextArea rows={4} placeholder="Nhập nội dung thông báo" />
        </Form.Item>

        <Button type="primary" onClick={() => form.submit()} loading={loading}>
          Gửi Thông Báo
        </Button>
      </Form>
    </Card>
  );
}
