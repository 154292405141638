import type {FormInstance, TableColumnsType} from 'antd';
import {
  Button,
  Col,
  Flex,
  Form,
  Input,
  Row,
  Select,
  Switch,
  Table,
  TimePicker,
  Upload,
} from 'antd';
import React, {memo, useEffect, useState} from 'react';

import {
  CloseOutlined,
  EditOutlined,
  LoadingOutlined,
  PlusOutlined,
} from '@ant-design/icons';

import {debounce} from 'lodash';

import {useWatch} from 'antd/es/form/Form';
import {useGetListCategory} from 'hooks/category';
import {useUpload} from 'hooks/upload/useUpload';
import {useGetSystemConfig} from 'hooks/useSystemConfig/useGetSystemConfig';
import AddressPicker from './AddressPicker';
import {TagRenderFC} from './component/TagRender';

const format = 'HH:mm';

interface Props {
  form: FormInstance<any>;
  isEdit: boolean;
  onValuesChange: () => void;
}

interface itemData {
  _id: string;
  name: string;
  costPerKm: number;
  costPerKmAfterSeed: number;
  distanceWithCostSimply: number;
  shopFeePerOrder: number;
  shipFeePerOrder: number;
}
const columnSystem: TableColumnsType = [
  {title: 'Tên bảng giá', dataIndex: 'name', key: 'name'},
  {title: 'Chi phí mỗi KM', dataIndex: 'costPerKm', key: 'costPerKm'},
  {
    title: 'Phí của hàng',
    dataIndex: 'shopFeePerOrder',
    key: 'shopFeePerOrder',
  },
  {title: 'Phí shiper', dataIndex: 'shipFeePerOrder', key: 'shipFeePerOrder'},
];

export const FormShop: React.FC<Props> = memo(
  ({form, isEdit, onValuesChange}) => {
    const isAlwaysOpen = useWatch(['isAlwaysOpen'], form);
    const [isEditAddress, setIsEditAddress] = useState(false);
    const [dataSystem, setDataSystem] = useState<any>([]);
    const [imageUrl, setImageUrl] = useState<string>('');
    const [filters, setFilters] = useState({});
    const {data: getData, isLoading} = useGetSystemConfig({
      ...filters,
      typeSystem: 'shop',
    });
    const {data: dataCategory} = useGetListCategory();
    const [isChangePassword, setIsChangePassword] = useState(false);
    const {mutateAsync: upLoadImage, isLoading: isLoadingUpload} = useUpload();

    const handleValuesChange = (e: any) => {
      const fieldName = Object.keys(e)[0];
      const value = e[fieldName];

      if (value && fieldName === 'type') {
        form.setFieldsValue({[fieldName]: value});
      }

      if (value && fieldName === 'systemConfigId') {
        const newData: any = getData?.result?.find(
          (v: itemData) => v._id === value,
        );
        if (newData) {
          const clone = {
            ...newData,
            key: newData._id,
          };
          setDataSystem([clone]);
        }
      } else if (fieldName === 'systemConfigId') {
        setDataSystem([]);
      }
      onValuesChange?.();
    };
    const optionsSystem = (arr = []) => {
      const newArrary = arr.map((item: any) => {
        return {
          label: item.name,
          value: item?._id,
        };
      });
      return newArrary;
    };
    const handleSearch = debounce(e => {
      if (e === null || e === undefined) return;
      setFilters({
        ...filters,
        name: e,
      });
    }, 1000);
    const beforeUpload = async (file: any) => {
      const isJpgOrPng =
        file.type === 'image/jpeg' || file.type === 'image/png';
      if (!isJpgOrPng) {
        console.log('You can only upload JPG/PNG file!');
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        console.log('Image must smaller than 2MB!');
      }
      return false;
    };
    const handleChange = async (e: any) => {
      const {file} = e;
      const formData = new FormData();
      formData.append('file', file);
      const res = await upLoadImage({body: formData});
      form.setFieldsValue({
        avatar: res,
      });
    };
    useEffect(() => {
      setImageUrl(form.getFieldValue('avatar'));
    }, [form, form.getFieldValue('avatar')]);

    const handleConfirmAddress = (
      address: string,
      lat: number,
      long: number,
    ) => {
      form.setFieldsValue({address, lat, long});
      setIsEditAddress(false);
    };

    return (
      <div className="mx-auto container">
        <Form form={form} onValuesChange={handleValuesChange} layout="vertical">
          <Row gutter={[24, 24]}>
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Số điện thoại</label>
                <Form.Item
                  name="numberPhone"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Input readOnly={isEdit} placeholder="Số điện thoại" />
                </Form.Item>
              </div>
            </Col>
            {isEdit ? (
              <Col span={isChangePassword ? 24 : 12}>
                {isChangePassword ? (
                  <Row gutter={[12, 12]}>
                    <Col span={12}>
                      <div className="flex flex-col gap-2">
                        <label className="font-bold text-2l">
                          Mật khẩu mới
                        </label>
                        <Form.Item
                          name="password"
                          className="mb-0"
                          rules={[
                            {
                              required: true,
                              message: 'Không được để trống!',
                            },
                          ]}>
                          <Input.Password />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flex flex-col gap-2">
                        <label className="font-bold text-2l">
                          Nhập lại mật khẩu
                        </label>
                        <Form.Item
                          name="confirmPassword"
                          className="mb-0"
                          dependencies={['password']}
                          hasFeedback
                          rules={[
                            {
                              required: true,
                              message: 'Không được để trống!',
                            },
                            ({getFieldValue}) => ({
                              validator(_, value) {
                                if (
                                  !value ||
                                  getFieldValue('password') === value
                                ) {
                                  return Promise.resolve();
                                }
                                return Promise.reject(
                                  new Error('Mật khẩu không trùng nhau!'),
                                );
                              },
                            }),
                          ]}>
                          <Input.Password />
                        </Form.Item>
                      </div>
                    </Col>
                    <Col span={12}>
                      <div className="flex items-end w-full">
                        <Button
                          danger
                          onClick={() => {
                            form.setFieldsValue({
                              password: '',
                              confirmPassword: '',
                            });
                            setIsChangePassword(false);
                          }}>
                          Trở lại
                        </Button>
                      </div>
                    </Col>
                  </Row>
                ) : (
                  <div className="flex items-end w-full h-full">
                    <Button
                      type="primary"
                      onClick={() => {
                        setIsChangePassword(true);
                      }}>
                      Đổi mật khẩu
                    </Button>
                  </div>
                )}
              </Col>
            ) : (
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="font-bold text-2l">Mật khẩu</label>
                  <Form.Item
                    name="password"
                    className="mb-0"
                    rules={[
                      {
                        required: true,
                        message: 'Không được để trống!',
                      },
                    ]}>
                    <Input.Password />
                  </Form.Item>
                </div>
              </Col>
            )}
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Ảnh đại diện</label>
                <Form.Item name="avatar" className="mb-0">
                  <Upload
                    listType="picture-card"
                    style={{width: '100%', position: 'relative', zIndex: 1}}
                    showUploadList={false}
                    onChange={handleChange}
                    fileList={[]}
                    beforeUpload={beforeUpload}>
                    {imageUrl ? (
                      <img
                        src={imageUrl}
                        alt="avatar"
                        style={{width: '100%', position: 'relative'}}
                      />
                    ) : (
                      <Button
                        style={{
                          border: 0,
                          background: 'transparent',
                          boxShadow: 'none',
                        }}>
                        {isLoadingUpload ? (
                          <LoadingOutlined />
                        ) : (
                          <PlusOutlined />
                        )}
                        <div style={{marginTop: 8}}>Upload</div>
                      </Button>
                    )}
                  </Upload>
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Tên Shop</label>
                <Form.Item
                  name="name"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Input placeholder="Tên Shop" />
                </Form.Item>
              </div>
            </Col>
            <Flex gap={24} flex={1} align="center">
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Giờ mở cửa</label>
                <Form.Item
                  name="openingHour"
                  className="mb-0"
                  dependencies={['isAlwaysOpen']}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <TimePicker format={format} disabled={isAlwaysOpen} />
                </Form.Item>
              </div>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Giờ đóng cửa</label>
                <Form.Item
                  name="closingHour"
                  className="mb-0"
                  dependencies={['isAlwaysOpen']}
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <TimePicker format={format} disabled={isAlwaysOpen} />
                </Form.Item>
              </div>
              <Form.Item name="isAlwaysOpen" className="mb-0">
                <Switch
                  checkedChildren={'Luôn mở cửa'}
                  unCheckedChildren={'Cấu hình tự động'}
                  onChange={(checked: boolean) => {
                    form.setFieldsValue({
                      isAlwaysOpen: checked,
                    });
                  }}
                />
              </Form.Item>
            </Flex>
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Loại</label>
                <Form.Item
                  name="type"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Select
                    mode="multiple"
                    tagRender={TagRenderFC}
                    tokenSeparators={[',']}
                    options={dataCategory?.map((item: any) => {
                      return {
                        value: item?.name,
                        label: item?.name,
                      };
                    })}
                  />
                </Form.Item>
              </div>
            </Col>
            <Col xs={24} md={12}>
              <div className="flex flex-col gap-2">
                <label className="font-bold text-2l">Bảng Giá</label>
                <Form.Item
                  name="systemConfigId"
                  className="mb-0"
                  rules={[
                    {
                      required: true,
                      message: 'Không được bỏ trống !',
                    },
                  ]}>
                  <Select
                    showSearch
                    loading={isLoading}
                    placeholder="Tìm kiếm tên bảng giá"
                    onSearch={handleSearch}
                    filterOption={false}
                    options={optionsSystem(getData?.result)}
                    allowClear
                  />
                </Form.Item>
              </div>
            </Col>
            {dataSystem?.length > 0 ? (
              <Col span={24}>
                <Table
                  columns={columnSystem}
                  dataSource={dataSystem}
                  pagination={false}
                />
              </Col>
            ) : null}
            <Col span={24}>
              <Form.Item
                label="Địa chỉ"
                name="address"
                className="mb-0"
                rules={[
                  {
                    required: true,
                    message: 'Không được bỏ trống !',
                  },
                ]}>
                <Input
                  placeholder="Địa chỉ"
                  readOnly
                  suffix={
                    <Button
                      type="link"
                      onClick={() => setIsEditAddress(prev => !prev)}>
                      {!isEditAddress ? <EditOutlined /> : <CloseOutlined />}
                    </Button>
                  }
                />
              </Form.Item>
              <Form.Item name="lat" className="mb-0 h-0">
                <Input type="hidden" />
              </Form.Item>
              <Form.Item name="long" className="mb-0 h-0">
                <Input type="hidden" />
              </Form.Item>
            </Col>
            {isEditAddress ? (
              <Col span={24}>
                <AddressPicker
                  onConfirm={handleConfirmAddress}
                  defaultLat={form.getFieldValue('lat')}
                  defaultLong={form.getFieldValue('long')}
                />
              </Col>
            ) : null}
          </Row>
        </Form>
      </div>
    );
  },
);
