import React, {useState, useTransition} from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  DatePicker,
  Divider,
  message,
  Checkbox,
} from 'antd';
import type {TableColumnsType} from 'antd';
import dayjs from 'dayjs';
import {EditFilled, PlusOutlined} from '@ant-design/icons';
import {useGetVouchers} from 'hooks/vouchers/useGetVouchers';
import {
  useUpdateVoucher,
  useCreateVoucher,
} from 'hooks/vouchers/useUpdateVoucher';
import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {
  formatNumberValue,
  formatNumberToString,
  formatStringToNumber,
} from 'utils';
export const Vouchers: React.FC = () => {
  const [isPending, startTransition] = useTransition();

  const [form] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isId, setIsId] = useState('');
  const {data: getData, isLoading, refetch} = useGetVouchers(filters);
  const {
    mutateAsync,
    isLoading: isCreate,
    isError: isErrorCreate,
  } = useCreateVoucher();
  const {
    mutateAsync: onUpdateVoucher,
    isLoading: isEdit,
    isError,
  } = useUpdateVoucher();

  const columns: TableColumnsType = [
    {title: 'Tên voucher', dataIndex: 'name', key: 'name', align: 'center'},
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
      align: 'center',
    },
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
    },
    {
      title: 'Đã sử dụng',
      dataIndex: 'applyCounter',
      key: 'applyCounter',
      align: 'center',
    },
    {title: 'Type', dataIndex: 'type', key: 'type', align: 'center'},
    {
      title: 'Ngày hết hạn',
      dataIndex: 'expriedAt',
      key: 'expriedAt',
      align: 'center',
      render: data => {
        return dayjs(data).format('DD/MM/YYYY');
      },
    },
    {title: 'Giá trị', dataIndex: 'value', key: 'value', align: 'center'},
    {
      title: 'Số lượng',
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'center',
    },
    {
      title: 'Giá tối đa áp dụng',
      dataIndex: 'maxPrice',
      key: 'price',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: 'Giá tối thiểu áp dụng',
      dataIndex: 'minPrice',
      key: 'minprice',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatNumberValue(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      render: (record: any) => {
        return (
          <div className="flex gap-4 px-0">
            <Button
              onClick={() => {
                const data = {
                  ...record,
                  expriedAt: dayjs(new Date(record.expriedAt)),
                  quantity: formatNumberToString(record.quantity),
                  maxPrice: formatNumberToString(record.maxPrice), 
                  minPrice: formatNumberToString(record.minPrice)
                };
                setIsId(data?._id);
                form.setFieldsValue(data);
                setIsShowPopup(true);
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            {/* <Button className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
              Xoá
            </Button> */}
          </div>
        );
      },
    },
  ];
  const addKeyData = (arr = []) => {
    const newArray = arr.map((item: any, index) => {
      return {...item, key: item?._id};
    });
    return newArray;
  };

  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
  
      const data = {
        ...value,
        quantity: formatStringToNumber(value.quantity),
        maxPrice: formatStringToNumber(value.maxPrice),
        minPrice: formatStringToNumber(value.minPrice),
        expriedAt: new Date(value.expriedAt).getTime(),
      };
     
      if (isId) {
        await onUpdateVoucher({
          idVoucher: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      await refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      if (isErrorCreate) {
        message.error('Tạo mới không thành công');
        return;
      }
      if (isError) {
        message.error('Cập nhật không thành công');
        return;
      }
    }
  };
  const normalizePrice = (value: any) => {
    return formatNumberToString(value);
  };
  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Mã giảm giá</h2>
          <p>{isPending ? 'loadng' : ''}</p>
          <Button
            className="font-bold"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              startTransition(() => {
                setIsId('');
                setIsShowPopup(true);
              });
            }}>
            Thêm mã giảm giá
          </Button>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              console.log(page, pageSize);
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
      </div>
      <Modal
        title={isId ? 'Thêm Voucher' : 'Chỉnh sửa voucher'}
        open={isShowPopup}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={onSubmit}
        confirmLoading={isCreate || isEdit}
        onCancel={() => {
          form.resetFields();
          setIsShowPopup(false);
        }}>
        <div className="container mx-auto ">
          <Divider className="my-2" />
          <Form form={form} className="py-1">
            <Row gutter={[12, 12]}>
              <Col span={24}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Tên Voucher</label>
                  <Form.Item name="name" className="mb-0">
                    <Input placeholder="Tên Voucher" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">
                    Phần trăm giá giảm
                  </label>
                  <Form.Item
                    name="value"
                    className="mb-0"
                    normalize={normalizePrice}>
                    <Input placeholder="0" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">
                    Giá tối thiểu áp dụng
                  </label>
                  <Form.Item
                    name="minPrice"
                    className="mb-0"
                    normalize={normalizePrice}>
                    <Input placeholder="0" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">
                    Giá tối đa áp dụng
                  </label>
                  <Form.Item
                    name="maxPrice"
                    className="mb-0"
                    normalize={normalizePrice}>
                    <Input placeholder="Max Price" />
                  </Form.Item>
                </div>
              </Col>

              <Col span={10}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Số lượng</label>
                  <Form.Item
                    name="quantity"
                    className="mb-0"
                    normalize={normalizePrice}>
                    <Input placeholder="Số lượng" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={14}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Type</label>
                  <Form.Item
                    name="type"
                    className="mb-0"
                    normalize={normalizePrice}>
                    <Input placeholder="Type" />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Ngày hết hạn</label>
                  <Form.Item name="expriedAt" className="mb-0">
                    <DatePicker
                      showTime
                      format="DD/MM/YYYY HH:mm:ss"
                      className="w-full"
                      placeholder="Ngày hết hạn"
                    />
                  </Form.Item>
                </div>
              </Col>
              <Col span={12} className="flex items-end gap-2">
                <div className="flex items-center  gap-2">
                  <label className="text-2l font-bold">Chỉ sử dụng 1 lần</label>
                  <Form.Item
                    name="isSingleUse"
                    valuePropName="checked"
                    className="mb-0">
                    <Checkbox />
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Mô tả</label>
                  <Form.Item name="description" className="mb-0">
                    <Input.TextArea autoSize={{minRows: 3, maxRows: 8}} />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
