import React, {useState, useTransition} from 'react';
import {
  Button,
  Modal,
  Form,
  Row,
  Col,
  Input,
  Divider,
  message,
  Popconfirm,
  Avatar,
  Tag,
  Upload,
} from 'antd';
import type {TableColumnsType} from 'antd';
import type {SearchProps} from 'antd/es/input/Search';

import {
  PlusOutlined,
  UnlockOutlined,
  LockOutlined,
  EditFilled,
  DollarOutlined,
  LoadingOutlined,
} from '@ant-design/icons';
import {useGetCategory,useUpdateCategory,useCreateCategory} from 'hooks/category';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {TableCustom} from 'components/element/TableCustom';
import {LoadedMoney} from 'components/element/LoadedMoney';
import {
  addKeyData,
  formatNumberNegativeToString,
  formatNumberValue,
  formatDate,
} from 'utils';
import {useUpload} from 'hooks/upload/useUpload';

export const Category: React.FC = () => {
  const [isPending, startTransition] = useTransition();

  const [form] = Form.useForm();
  const [formToMoney] = Form.useForm();
  const [filters, setFilters] = useState({});
  const [isOpen, setIsOpen] = useState(false);
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [isId, setIsId] = useState('');
  const [isChangePassword, setIsChangePassword] = useState(false);
  const {data: getData, isLoading, refetch} = useGetCategory(filters);
  const {mutateAsync: upLoadImage, isLoading: isLoadingUpload} = useUpload();

  const {
    mutateAsync,
    isLoading: isCreate,
    isError: isErrorCreate,
  } = useCreateCategory();

  const {
    mutateAsync: onUpdateUser,
    isLoading: isEdit,
    isError,
  } = useUpdateCategory();
 
  const columns: TableColumnsType = [
    {title: 'Tên', dataIndex: 'name', key: 'name', align: 'center'},
    {
      title: 'Hình ảnh',
      dataIndex: 'image',
      key: 'avatar',
      align: 'center',
      render: data => {
        return <Avatar src={data} />;
      },
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      align: 'center',
      render: data => {
        return (
          <div>
            <p>{formatDate(data)}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      width: 300,
      render: (text, record: any) => {
        return (
          <div className="flex justify-center gap-2 px-0">
            <Button
              onClick={() => {
                const data = {
                  ...record,
                };
                setIsId(data?._id);
                form.setFieldsValue(data);
                setIsShowPopup(true);
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            {/* <Button className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
              Xoá
            </Button> */}
          </div>
        );
      },
    },
  ];

  const onSubmit = async () => {
    try {
      const value = await form.validateFields();
      const data = {...value};
      console.log(data);
      if (isId) {
        await onUpdateUser({
          id: isId,
          body: data,
        });
      } else {
        await mutateAsync(data);
      }
      await refetch();
      setIsShowPopup(false);
      setIsId('');
      form.resetFields();
    } catch (error) {
      if(error?.data?.message){
        message.error(error?.data?.message);
      }
     
    }
  };
  const onSeachValue: SearchProps['onSearch'] = (value, _e, info) => {
    setFilters({...filters, search: value});
  };
  const beforeUpload = async (file: any) => {
    const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
      console.log('You can only upload JPG/PNG file!');
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      console.log('Image must smaller than 2MB!');
    }
    return false;
  };
  const handleChange = async (e: any) => {
    const {file} = e;
    const formData = new FormData();
    formData.append('file', file);
    const res = await upLoadImage({body: formData});
    form.setFieldsValue({
      image: res,
    });
  };

  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Loại món ăn</h2>
          <div className="flex gap-2">
            <div>
              <Input.Search
                className="w-96"
                placeholder="Search theo loại"
                onSearch={onSeachValue}
                enterButton
              />
            </div>
            <Button
              className="font-bold"
              type="primary"
              icon={<PlusOutlined />}
              onClick={() => {
                startTransition(() => {
                  setIsId('');
                  setIsShowPopup(true);
                });
              }}>
              Thêm loại món ăn
            </Button>
          </div>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
          {isOpen ? (
            <LoadedMoney
              form={formToMoney}
              setIsOpen={setIsOpen}
              isOpen={isOpen}
              refetch={refetch}
            />
          ) : null}
        </div>
      </div>
      <Modal
        title={!isId ? 'Thêm' : 'Chỉnh sửa'}
        open={isShowPopup}
        okText="Lưu"
        cancelText="Huỷ"
        onOk={onSubmit}
        confirmLoading={isCreate || isEdit}
        onCancel={() => {
          form.resetFields();
          setIsChangePassword(false);
          setIsShowPopup(false);
        }}>
        <div className="container mx-auto ">
          <Divider className="my-2" />

          <Form form={form} className="py-1">
            <Row gutter={[12, 12]}>
              <Col span={12}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Hình ảnh</label>
                  <Form.Item name="image" className="mb-0">
                    <Upload
                      listType="picture-card"
                      fileList={[]}
                      showUploadList={false}
                      onChange={handleChange}
                      beforeUpload={beforeUpload}>
                      {form.getFieldValue('image') ? (
                        <img
                          src={form.getFieldValue('image')}
                          alt="avatar"
                          style={{width: '100%'}}
                        />
                      ) : (
                        <Button
                          style={{
                            border: 0,
                            background: 'transparent',
                            boxShadow: 'none',
                          }}>
                          {isLoadingUpload ? (
                            <LoadingOutlined />
                          ) : (
                            <PlusOutlined />
                          )}
                          <div style={{marginTop: 8}}>Upload</div>
                        </Button>
                      )}
                    </Upload>
                  </Form.Item>
                </div>
              </Col>
              <Col span={24}>
                <div className="flex flex-col gap-2">
                  <label className="text-2l font-bold">Tên</label>
                  <Form.Item name="name" className="mb-0">
                    <Input placeholder="Tên" />
                  </Form.Item>
                </div>
              </Col>
            </Row>
          </Form>
        </div>
      </Modal>
    </div>
  );
};
