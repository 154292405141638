import React from 'react';

import {BalanceHistory} from 'pages/balance';
import {Category} from 'pages/category';
import Notification from 'pages/notifications';
import {Orders} from 'pages/order';
import {OrderByID} from 'pages/order/id';
import {Reports} from 'pages/report';
import {Shiper} from 'pages/ship';
import {Shop} from 'pages/shop';
import {CreateShop} from 'pages/shop/create';
import {EditShop} from 'pages/shop/edit';
import {MenuShop} from 'pages/shop/menu';
import {Statistic} from 'pages/statistic';
import {SystemConfig} from 'pages/systemConfig';
import {ShopToSystem} from 'pages/systemConfig/shop';
import {SystemConfigShip} from 'pages/systemConfigShip';
import {ShipToSystem} from 'pages/systemConfigShip/ship';
import {User} from 'pages/user';
import {OrderUser} from 'pages/user/order';
import {Vouchers} from 'pages/vouchers';

export const privateRouters = [
  {path: '/shop', component: <Shop />},
  {path: '/shop/create', component: <CreateShop />},
  {path: '/shop/edit/:id', component: <EditShop />},
  {path: '/shop/menu/:id', component: <MenuShop />},
  {path: '/shiper', component: <Shiper />},
  {path: '/user', component: <User />},
  {path: '/user/order/:id', component: <OrderUser />},
  {path: '/vouchers', component: <Vouchers />},
  {path: '/system-config', component: <SystemConfig />},
  {path: '/system-config/shop/:id', component: <ShopToSystem />},
  {path: '/system-config-ship', component: <SystemConfigShip />},
  {path: '/system-config-ship/ship/:id', component: <ShipToSystem />},
  {path: '/orders', component: <Orders />},
  {path: '/orders/:id', component: <OrderByID />},
  {path: '/statistic', component: <Statistic />},
  {path: '/reports', component: <Reports />},
  {path: '/balance/:id', component: <BalanceHistory />},
  {path: '/category', component: <Category />},
  {path: '/notification', component: <Notification />},
];
