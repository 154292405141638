import React, {useState, useTransition} from 'react';
import {Button, Form,} from 'antd';
import type {TableColumnsType} from 'antd';
import {
  PlusOutlined,
  EditFilled,
  ReadOutlined,
} from '@ant-design/icons';
import {useNavigate} from 'react-router-dom';
import {useGetSystemConfig} from 'hooks/useSystemConfig/useGetSystemConfig';

import {TableCustom} from 'components/element/TableCustom';
import {PaginationCustom} from 'components/element/PaginationCustom';
import {
  addKeyData,
  formatNumberToString,
  formatNumberValue,
} from 'utils/index';
import { FormSystemShip } from 'components/systemConfig/FormSystemShip';


export const SystemConfigShip: React.FC = () => {
  const [isPending, startTransition] = useTransition();
  const navigate = useNavigate();
  const [filters, setFilters] = useState({
    page: 1,
    pageSize: 10,
    typeSystem: 'ship'
  });
  const [isId, setIsId] = useState('');
  const [isShowPopup, setIsShowPopup] = useState(false);
  const [form] = Form.useForm();

  const {data: getData, isLoading, refetch} = useGetSystemConfig(filters);

  const columns: TableColumnsType = [
    {title: 'Tên bảng giá', dataIndex: 'name', key: 'name', fixed: 'left'},
    {
      title: 'Phí giao hàng',
      dataIndex: 'shipFeePerOrder',
      key: 'price',
      render:  (data,record: any) => {
        const isPercentShipFee = !record.isPercentShipFee;
        return (
          <div>
            <p>{isPercentShipFee ? formatNumberValue(data) : `${formatNumberValue(data,'%')} theo phí ship`}</p>
          </div>
        );
      },
    },
    {
      title: '',
      dataIndex: '',
      key: 'action',
      align: 'center',
      fixed: 'right',
      width: 400,
      render: (record: any) => {
        return (
          <div className="flex justify-center gap-4 px-0">
            <Button
              type="primary"
              icon={<ReadOutlined />}
              onClick={() => {
                navigate(`/system-config-ship/ship/${record?._id}`, {
                  state: {from: `/system-config`},
                });
              }}>
              Tài xế theo bảng giá
            </Button>
            <Button
              onClick={() => {
                const data = {
                  ...record,
                  costPerKm: formatNumberToString(record.costPerKm || 0),
                  shopFeePerOrder: formatNumberToString(
                    record.shopFeePerOrder || 0,
                  ),
                  shipFeePerOrder: formatNumberToString(
                    record.shipFeePerOrder || 0,
                  ),
                  costPerKmAfterSeed: formatNumberToString(
                    record.costPerKmAfterSeed || 0,
                  ),
                  distanceWithCostSimply: formatNumberToString(
                    record.distanceWithCostSimply || 0,
                  ),
                };
                setIsId(record?._id);
                form.setFieldsValue(data);
                setIsShowPopup(true);
              }}
              icon={<EditFilled />}
              className="!border-white	 bg-[#eca52b] hover:!bg-[#edb555] !text-white !font-medium">
              Chỉnh sửa
            </Button>
            {/* <Button className="!border-white bg-[#ed250e] hover:!bg-[#f25c4b] !text-white !font-medium">
                Xoá
              </Button> */}
          </div>
        );
      },
    },
  ];

  return (
    <div className="container mx-auto">
      <div className="flex-col gap-4">
        <div className="flex justify-between items-center">
          <h2 className="text-2xl font-bold">Bảng giá Tài xế</h2>
          <Button
            className="font-bold"
            type="primary"
            icon={<PlusOutlined />}
            onClick={() => {
              startTransition(() => {
                setIsId('');
                setIsShowPopup(true);
              });
            }}>
            Thêm bảng giá
          </Button>
        </div>
        <div className=" flex flex-col items-end gap-3">
          <TableCustom
            isLoading={isLoading}
            columns={columns}
            data={addKeyData(getData?.result)}
          />
          <PaginationCustom
            totalPage={getData?.totalPage ?? 1}
            current={getData?.page ?? 1}
            pageSize={getData?.pageSize ?? 10}
            onChange={(page, pageSize) => {
              setFilters({...filters, page, pageSize});
            }}
            onShowSizeChange={(current, size) => {
              setFilters({...filters, page: current, pageSize: size});
            }}
          />
        </div>
       <FormSystemShip isId={isId} setIsId={setIsId} setIsShowPopup={setIsShowPopup} isShowPopup={isShowPopup}  form={form} refetch={refetch} />
      </div>
    </div>
  );
};
