import { AxiosError } from 'axios';
import Api from "configs";
import { useQuery } from "@tanstack/react-query";
import GetResponse from 'types/GetResponse';
import GetFilters from 'types/GetFilter';

export const useGetUsers = (filters: GetFilters) => {
    const {
        pageSize = 10,
        page = 1,
        search = ''
    } = filters;
    const options = {
        query: {},
        pageSize: pageSize,
        page: page
    }
    if (search) {
        options.query = {
            ...options.query,
            search: search
        }
    }
    return useQuery<GetResponse, AxiosError>({
        queryKey: ['useGetUsers', options],
        queryFn: async () => {
            const { data } = await Api.get('/admin/client', { params: options });
            return data;
        },

    });
}


export const useGetUserDetails = (id: string) => {
    return useQuery({
        queryKey: ['useGetUserDetails', id],
        queryFn: async () => {
            const { data } = await Api.get(`/admin/client/${id}`);
            return data;
        }
    })
}