import React, {useState, useEffect} from 'react';
import {useNavigate, Outlet} from 'react-router-dom';
import {MenuFoldOutlined, MenuUnfoldOutlined} from '@ant-design/icons';
import {Layout, Button, theme} from 'antd';
import {UserInfo} from './compoment/UserInfo';
import {LeftMenu} from './compoment/LeftMenu';

const {Header, Sider, Content} = Layout;

interface DashboardPageProps {
  isAuthenticated: boolean;
}
export const DashboardPage: React.FC<DashboardPageProps> = ({
  isAuthenticated,
}) => {
  const [collapsed, setCollapsed] = useState(false);
  const {
    token: {colorBgContainer, borderRadiusLG},
  } = theme.useToken();
  let navigate = useNavigate();
  useEffect(() => {
    if (!isAuthenticated) {
      navigate('/login', {replace: true, state: {from: '/'}});
    }
  }, [isAuthenticated, navigate]);

  return (
    <Layout>
      <Sider
        trigger={null}
        collapsible
        collapsed={collapsed}
        style={{
          overflow: 'auto',
          height: '100vh',
          left: 0,
          top: 0,
          bottom: 0,
        }}>
        <div className="flex justify-center demo-logo-vertical">
          <img
            style={{
              width: !collapsed ? '150px' : '50px',
              height: !collapsed ? '150px' : '50px',
              borderRadius: '32px',
            }}
            src={require('../../assets/img/logo.png')}
            alt="Logo"
          />
        </div>
        <LeftMenu />
      </Sider>
      <Layout>
        <Header
          className="bg-white pl-0 pr-3 flex justify-between"
          style={{background: colorBgContainer}}>
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <div className="flex items-center gap-10 px-2">
            <UserInfo />
          </div>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            height: 800,
            overflowY: 'scroll',
          }}>
          <Outlet />
        </Content>
      </Layout>
    </Layout>
  );
};
